import React from 'react'

import { Helmet } from 'react-helmet'

import SingUp from '../components/sing-up'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Extension login</title>
        <meta property="og:title" content="Extension login" />
      </Helmet>
      <SingUp></SingUp>
    </div>
  )
}

export default Home
