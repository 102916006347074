import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom';

import './sing-up.css'
import { userAuth } from '../database/firebase'

const SingUp = (props) => {
  const [email, setEmail] = useState(localStorage.getItem("trafflsExtensionEmail") || "")
  const [password, setPassword] = useState(localStorage.getItem("trafflsExtensionPassword") || "")

  const [passwordLine, setPasswordLine] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [passwordStatus, setPasswordStatus] = useState(0)
  const [emailLine, setEmailLine] = useState(false)
  const [emailStatus, setEmailStatus] = useState(0)

  const [signUpText, setSignUpText] = useState('Sing up');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let intervalId;

    if (loading) {
      let dotCount = 0;
      intervalId = setInterval(() => {
        dotCount = (dotCount + 1) % 4;
        setSignUpText(`Sing up${'.'.repeat(dotCount)}`);
      }, 200);
    } else {
      setSignUpText('Sing up');
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [loading]);

  const eventChange = (event) => {
    const id = event.target.id;
    switch (id) {
      case 'email':

        const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (emailReg.test(event.target.value)) {
          setEmailStatus(1);
        } else {
          setEmailStatus(0);
        }
        setEmail(event.target.value);
        break;
      case 'password':
        if (event.target.value.length > 5) {
          setPasswordStatus(1);
        } else {
          setPasswordStatus(0);
        }
        setPassword(event.target.value);
        break;
      default:
    }
  };

  const login = async () => {
    let auth = await userAuth(email, password);
    if (auth.status) {
      let db = auth.db;
      setCookie("TCE_COMPANY_NAME", db.company_name);
      setCookie("TCE_SUPPORT_ID", auth.uid);
      history.push('/sing-up-successfull');
    } else {
      setLoading(false);
      setEmailStatus(2);
      setPasswordStatus(2);
    }
  }

  function setCookie(name, value) {
    document.cookie = name + "=" + (value || "");
  }

  return (
    <div className="sing-up-sing-up">
      <div className="sing-up-container">
        <div className="sing-up-container01">
          <div className="sing-up-container02">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="sing-up-image"
            />
            <span className="sing-up-text">Sign up</span>
          </div>
          <span className="sing-up-text01">
            Keep your LinkedIn session active. Seamless cookie updates for uninterrupted work.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </div>
        <div className="sing-up-container03">
          <div className="sing-up-container04">
            <div className="sing-up-container05">
              <span>Traffls Extension</span>
              <span className="sing-up-text03">V1.0.0</span>
            </div>
          </div>
          <div className="sing-up-container06">
            <div className="sing-up-container07">
              <span className="sing-up-text04">Login</span>
              <span className="sing-up-text05">
                Bitte loge dich ein um die Extension zu aktivieren.
              </span>
            </div>
            <div className="sing-up-container08">
              <div className="sing-up-container09">
                <div className="sing-up-container10">
                  <span className="sing-up-text06">Email Adresse</span>
                  <div className="sing-up-container11">
                    <div className="sing-up-container12">
                      <input
                        onChange={(event) => {
                          eventChange(event);
                          setEmailLine(event.target.value !== '');
                        }}
                        onFocus={() => setEmailLine(true)}
                        onBlur={() => {
                          if (email !== '') {
                            setEmailLine(true);
                          } else {
                            setEmailLine(false);
                          }
                        }}
                        id="email"
                        value={email}
                        type="text"
                        placeholder="Enter your email address here"
                        className="sing-up-textinput input"
                      />
                      {emailStatus === 1 && (
                        <div className="sing-up-container13">
                          <svg viewBox="0 0 1024 1024" className="sing-up-icon">
                            <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                          </svg>
                        </div>
                      )}
                      {emailStatus === 2 && (
                        <div className="sing-up-container14">
                          <svg
                            viewBox="0 0 1024 1024"
                            className="sing-up-icon02"
                          >
                            <path d="M426 128h172v512h-172v-512zM426 810q0-36 25-60t61-24 61 24 25 60-25 61-61 25-61-25-25-61z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                    {!emailLine && <div className="sing-up-container15"></div>}
                    {emailLine && <div className="sing-up-container16"></div>}
                  </div>
                </div>
                <div className="sing-up-container17">
                  <span className="sing-up-text07">Password</span>
                  <div className="sing-up-container18">
                    <div className="sing-up-container19">
                      <input
                        onChange={(event) => {
                          eventChange(event);
                          setPasswordLine(event.target.value !== '');
                        }}
                        onFocus={() => setPasswordLine(true)}
                        onBlur={() => {
                          if (password !== '') {
                            setPasswordLine(true);
                          } else {
                            setPasswordLine(false);
                          }
                        }}
                        id="password"
                        value={password}
                        type="password"
                        placeholder="Enter your password here"
                        className="sing-up-textinput input"
                      />
                      {passwordStatus === 1 && (
                        <div className="sing-up-container20">
                          <svg
                            viewBox="0 0 1024 1024"
                            className="sing-up-icon04"
                          >
                            <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                          </svg>
                        </div>
                      )}
                      {passwordStatus === 2 && (
                        <div className="sing-up-container21">
                          <svg
                            viewBox="0 0 1024 1024"
                            className="sing-up-icon06"
                          >
                            <path d="M426 128h172v512h-172v-512zM426 810q0-36 25-60t61-24 61 24 25 60-25 61-61 25-61-25-25-61z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                    {!passwordLine && (
                      <div className="sing-up-container22"></div>
                    )}
                    {passwordLine && (
                      <div className="sing-up-container23"></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="sing-up-container24">
                <div className="sing-up-container25">
                  <div
                    onClick={() => setConfirm(!confirm)}
                    className="sing-up-container26"
                  >
                    {confirm && (
                      <div className="sing-up-container27">
                        <svg viewBox="0 0 1024 1024" className="sing-up-icon08">
                          <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                  <span className="sing-up-text08">
                    By confirming this selection, you agree to allow the transmission of the LinkedIn session cookie to traffls.
                  </span>
                </div>
                {!confirm && (
                  <div className="sing-up-container28">
                    <span className="sing-up-text09">Confirm to Sign up</span>
                  </div>
                )}
                {confirm && (
                  <div
                    className="sing-up-container29"
                    onClick={async () => {
                      setLoading(true);
                      await login()
                      if (email === '') {
                        setEmailStatus(2);
                      }
                      if (password === '') {
                        setPasswordStatus(2);
                      }
                    }}
                  >
                    <span>{signUpText}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SingUp.defaultProps = {
  imageSrc: '/logo_11-200h.webp',
  imageAlt: 'image',
}

SingUp.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default SingUp
