import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set, off, get, child } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB2E0kURluRfqjf9NXVtieD2KUj2Utb7yo",
    authDomain: "up2data-v2-9c37f.firebaseapp.com",
    databaseURL: "https://up2data-v2-9c37f-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "up2data-v2-9c37f",
    storageBucket: "up2data-v2-9c37f.appspot.com",
    messagingSenderId: "349484898398",
    appId: "1:349484898398:web:da868af42792ba127dfccf",
    measurementId: "G-6CD4XWHQZ2"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export async function userAuth(email, password) {
    return new Promise(function (resolve) {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const client = userCredential.user;
                const db = await getClientDatabase(client.uid);
                if(db){
                    resolve({ status: true, uid: client.uid, db });
                }else{
                    resolve({ status: false });
                }
            })
            .catch((error) => {
                resolve({ status: false })
            });
    });
};



export async function getClientDatabase(clientId) {
    console.log('get client database: ' + clientId);
    return new Promise(function (resolve) {
        const dbRef = ref(db);
        get(child(dbRef, `extension/${clientId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val()
                resolve(data)
            } else {
                resolve(false);
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    });
}