import React from 'react'

import { Helmet } from 'react-helmet'

import './sing-up-successfull.css'

const SingUpSuccessfull = (props) => {
  return (
    <div className="sing-up-successfull-container">
      <Helmet>
        <title>SingUpSuccessfull - Extension login</title>
        <meta
          property="og:title"
          content="SingUpSuccessfull - Extension login"
        />
      </Helmet>
      <div className="sing-up-successfull-container01">
        <div className="sing-up-successfull-container02">
          <div className="sing-up-successfull-container03">
            <div className="sing-up-successfull-container04">
              <img
                alt="image"
                src="/logo_11-200h.webp"
                className="sing-up-successfull-image"
              />
              <span className="sing-up-successfull-text">Sign up</span>
            </div>
            <span className="sing-up-successfull-text1">
              By confirming this area, you are giving your consent.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="sing-up-successfull-container05">
            <span className="sing-up-successfull-text2">Login successful</span>
            <div className="sing-up-successfull-container06">
              <div className="sing-up-successfull-container07">
                <div className="sing-up-successfull-container08">
                  <span>Traffls Extension</span>
                  <span className="sing-up-successfull-text4">V1.0.0</span>
                </div>
              </div>
              <div className="sing-up-successfull-container09">
                <span className="sing-up-successfull-text5">
                  <span>
                    You have been successfully logged in. You may now close this
                    tab
                  </span>
                  <span>.</span>
                </span>
                <span className="sing-up-successfull-text8">
                  Please note that the extension is disabled by default and
                  needs to be enabled for proper functionality.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingUpSuccessfull
